.companies {
  background-color: #f8f8f8;

  &__actions {
    margin: 5rem 0 2rem 0;

    button {
      margin-right: 2rem;
    }
  }

  &__modalTitle {
    color: #5e5873;
  }

  &__modal .MuiDialogContent-root {
    background-color: #f8f8f8;
  }

  &__modalSubtitle {
    color: #5e5873;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-weight: unset;

    svg {
      margin-right: 1rem;
    }
  }

  &__bar {
    margin-bottom: 2rem;
  }

  &__icon {
    width: 2rem;
    cursor: pointer;
  }

  &__content {
    min-height: 90vh;
    padding: 0 2.8rem;
  }

  table thead tr th {
    background-color: #f8f8f8;
    color: #5e5873;
    padding: 10px 16px;
    font-size: 1.2rem;
  }

  table tbody tr td {
    background-color: #fff;
    color: #6e6b7b;
  }

  table tbody tr td:nth-child(1) {
    color: #7367f0;
    font-weight: 600;
  }
}
