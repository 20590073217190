* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: "Roboto", sans-serif;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  font-size: 62.5%;
}
